import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { ModuleWrapper } from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';
import { PortableTextBlock } from '@components/sanity/portableTextBlock';

const PageBanner = ({ config, image, bannerText }) => {
  const breakpoints = useBreakpoint();
  return (
    <ModuleWrapper {...config}>
      <div className='relative w-full md:flex md:h-screen'>
        <div className='aspect-w-5 aspect-h-4 relative bg-black md:aspect-none md:h-full md:w-full md:absolute md:z-0'>
          <SanityImage
            image={image}
            className='absolute w-full h-full inset-0 object-cover z-0 opacity-60 md:h-full md:w-full'
          />
        </div>
        <div className='relative z-10 pt-10 px-nav-gutter self-end pb-10 xl:pb-0 md:text-white xl:self-center'>
          <PortableTextBlock
            text={bannerText}
            className='max-w-lg '
            reversed={breakpoints.lg}
          />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default PageBanner;
